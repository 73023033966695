import { GridSection } from "components/CardComponents";
import NumberInput from "components/Forms/NumberInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import Condition from "components/Forms/Condition";
import { CardSectionDefaultProps } from "interfaces/cardSection";

export default function SpaceAmenities({
  configName,
  propertyName,
}: CardSectionDefaultProps): JSX.Element {
  return (
    <GridSection title="Space Amenities" md={4} xs={6}>
      <NullBooleanInput
        name={`${propertyName}.parking_car`}
        label={`Car Parking`}
      />

      <Condition when={`${propertyName}.parking_car`} is={true}>
        <NumberInput
          name={`${propertyName}.parking_car_number`}
          label={"How many cars/spot available for tenant?"}
        />
      </Condition>

      <NumberInput
        name={`${configName}.power_amps`}
        label={`Electrical - Amps`}
      />

      <NullBooleanInput
        name={`${configName}.power_3phase`}
        label={`Electrical - 3 Phase`}
      />

      <NullBooleanInput
        name={`${configName}.temperature_refrig`}
        label={`Refrigeration`}
      />

      <NullBooleanInput
        name={`${configName}.temperature_freeze`}
        label={`Freezer`}
      />

      <NullBooleanInput
        name={`${configName}.temperature_ac`}
        sideNoteNamespace={`${configName}.temperature`}
        label={`Air Conditioning`}
      />
      <NullBooleanInput name={`${propertyName}.bathrooms`} label={`Bathroom`} />

      <NullBooleanInput name={`${configName}.heating`} label={`Heating`} />
    </GridSection>
  );
}
