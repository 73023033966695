import { GridSection } from "components/CardComponents";
import TextInput from "components/Forms/TextInput";
import NumberInput from "components/Forms/NumberInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import TextAreaInput from "components/Forms/TextAreaInput";
import { CardSectionDefaultProps } from "interfaces/cardSection";
import { Grid } from "@mui/material";

export default function PropertyDetails({
  propertyName,
}: CardSectionDefaultProps): JSX.Element {
  return (
    <GridSection title="Other Details" md={12} xs={12}>
      <Grid item md={4} xs={6}>
        <NumberInput
          name={`${propertyName}.total_size`}
          label={`Total Size (sq. ft.)`}
        />
        <NumberInput
          name={`${propertyName}.total_tenants`}
          label={`Number of Tenants`}
        />
        <NullBooleanInput
          name={`${propertyName}.multiple_guests`}
          label={`Open to Multiple Guests`}
        />
        <TextInput
          name={`${propertyName}.building_zoning`}
          label={`Building Zoning Code/Type`}
        />
        <NullBooleanInput
          name={`${propertyName}.broker`}
          label={`Working with a Broker`}
        />
        <TextAreaInput
          name={`${propertyName}.house_rules`}
          label={`House Rules`}
        />
      </Grid>
    </GridSection>
  );
}
