import __ from "lodash";
import moment from "moment";

import {
  LFB_SQFT_MO,
  LFB_SQFT_YR,
  PT_WAREHOUSE,
  PS_BOOKED_NOMATCH,
  PS_BOOKED_MATCH,
  PS_UNAVAILABLE,
  COUNTRY_US,
} from "./constants";

import { EnumListFeeBasisWarehouse } from "./enums";

const prepareIdForAdding = (id) => {
  return id < 0 ? null : id;
};

const buildToolTip = (title, content) => {
  return (
    <>
      <p className="text-base w-max text-lg font-medium">{title}</p>
      <div>{content}</div>
    </>
  );
};

const getEnumName = (enumValue, enumList) => {
  if (enumValue == null) return "";

  return enumList.find((search) => search.value === enumValue)?.name;
};

const getLengthHeader = (length) =>
  !length || length === 1 ? "" : `(${length})`;

const createConfiguration = (name, config_id, reasons) => {
  return {
    name,
    config_id,
    status: "PS_PENDING",
    is_office_size_included: true,
    listings: { providers: [] },
    listable: { result: false, reasons },
  };
};

const cloneConfiguration = (dataToClone, name, newId) => {
  const cloneData = __.cloneDeep(dataToClone);

  cloneData.config_id = newId;
  cloneData.name = name;

  delete cloneData.pipedrive_product_id;
  delete cloneData.trackcode;

  return cloneData;
};

const showInputErrorStyle = (hasError, inputValue) => {
  return hasError || inputValue == null ? "input-error" : "input-default";
};

const addDisabledStyle = (isDisabled, style) => {
  const errorStyle = "opacity-50 pointer-events-none";
  const disabledStyle = style ? `${style} ${errorStyle}` : errorStyle;

  return isDisabled ? disabledStyle : style;
};

const objectListToStringNames = (list, parentName) => {
  const stringNames = [];

  for (let parentIndex = 0; parentIndex < list.length; parentIndex++) {
    const element = list[parentIndex];
    const name = `${parentName}[${parentIndex}]`;

    Object.keys(element).forEach((childKeyOne) => {
      const selectedProperty = element[childKeyOne];

      if (!__.isEmpty(selectedProperty) && !Array.isArray(selectedProperty)) {
        stringNames.push(
          createFormErrorObject(`${name}.${childKeyOne}`, parentIndex, null)
        );
      }

      element?.configs?.forEach((config, configIndex) => {
        if (!__.isEmpty(config)) {
          Object.keys(config).forEach((childKeyTwo) => {
            if (!__.isEmpty(config[childKeyTwo])) {
              stringNames.push(
                createFormErrorObject(
                  `${name}.configs[${configIndex}].${childKeyTwo}`,
                  parentIndex,
                  configIndex
                )
              );
            }
          });
        }
      });
    });
  }

  return stringNames;
};

const createFormErrorObject = (name, parentIndex, configIndex) => {
  return { name, parentIndex, configIndex };
};

const cloneRequest = (dataToClone, name, newId) => {
  const cloneData = __.cloneDeep(dataToClone);

  cloneData.request_id = newId;
  cloneData.name = name;

  return cloneData;
};

const tenantRequestParent = (length, creationTime, requestName) => {
  if (length === 0) return "No Tenant Request";

  return length === 1
    ? isEmptyString(requestName)
      ? `Request: ${moment(creationTime).format("MMM DD YYYY")}`
      : requestName
    : `Tenant Requests (${length})`;
};

const findErrorIndexes = (errors) => {
  const formErrors = {
    parentHasError: false,
    childHasErrors: [],
  };

  if (errors == null || errors?.length < 1) return formErrors;

  Object.keys(errors).forEach((key) => {
    if (key !== "configs" && errors[key] != null) {
      formErrors.parentHasError = true;
    }
  });

  formErrors.childHasErrors = findErrorInConfigurations(errors?.configs);

  return formErrors;
};

const findErrorsInRequests = (errors, requestLength) => {
  const formErrors = {
    parentHasError: false,
    childHasErrors: [],
  };

  if (errors === null || errors?.length < 1) return formErrors;

  formErrors.childHasErrors = findErrorInConfigurations(errors);

  if (requestLength === 1 && formErrors.childHasErrors.length > 0) {
    formErrors.parentHasError = true;
  }

  return formErrors;
};

const findErrorInConfigurations = (configs) => {
  const errors = [];
  for (let index = 0; index < configs?.length; index++) {
    if (!__.isEmpty(configs[index])) errors.push(index);
  }
  return errors;
};

const createDeleteConfig = (id, type) => {
  return { entity_id: id, entity_type: type };
};

const initializeEmptyProperty = (newConfigId, defaultFailedReasons) => {
  return {
    property_id: -Math.abs(makeid(10)),
    property_type: PT_WAREHOUSE,
    photos: [],
    floorplans: [],
    exclusives: [],
    configs: [createConfiguration(null, newConfigId, defaultFailedReasons)],
  };
};

// returns a string representation of the date and time in the given UTC time
// string.
export const convertToDisplayTime = (utcTimeStr) => {
  if (isUndefinedOrNull(utcTimeStr)) return "N/A";
  // convert to utc time string for proper conversion to local time
  utcTimeStr = utcTimeStr.replaceAll("-", "/") + " UTC";
  let date = new Date(utcTimeStr);
  let year = date.getFullYear();
  let month = date.toLocaleString("default", { month: "short" });
  let day = date.getDate().toString().padStart(2, "0");
  let hours = date.getHours().toString().padStart(2, "0");
  let mins = date.getMinutes().toString().padStart(2, "0");
  let isAm = hours < 12;
  if (hours === "00") hours = "12";
  if (!isAm && hours !== "12") {
    hours -= 12;
    hours = hours.toString().padStart(2, "0");
  }
  return `${year} ${month} ${day} ${hours}:${mins}${isAm ? "am" : "pm"}`;
};

function convertPermissionsToGQLInput(permissions, allPermissions) {
  let ret = [];
  permissions.forEach(function (value, i) {
    if (value === true) {
      ret.push(allPermissions[i]);
    }
  });
  return ret;
}

function convertPermissionsFromGQLResponse(permissions, allPermissions) {
  let ret = [];
  for (var i = 0; i < allPermissions.length; i++) {
    ret.push(false);
  }

  allPermissions.forEach(function (value, i) {
    if (permissions.includes(value)) {
      ret[i] = true;
    }
  });

  return ret;
}

function buildPropertyName(address, unit, city, configName) {
  let builtName = address;
  if (unit) {
    builtName = unit + " - " + address;
  }

  if (city) {
    builtName += `, ${city}`;
  }

  if (configName) builtName += `: ${configName}`;

  return builtName;
}

function checkPassword(input) {
  var passw = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}/;
  if (input.match(passw)) {
    return true;
  } else {
    return false;
  }
}

// TODO: getFileType() should be refacted using a switch statement for clarity
function getFileType(type) {
  const str = type.toString().toLowerCase();
  if (str.includes("jpg") || str.includes("jpeg")) {
    return "FT_JPEG";
  }
  if (str.includes("png")) {
    return "FT_PNG";
  }
  if (str.includes("tiff")) {
    return "FT_TIFF";
  }
  if (str.includes("pdf")) {
    return "FT_PDF";
  }
  if (str.includes("docx")) {
    return "FT_DOCX";
  }

  return "FT_UNKNOWN";
}

function makeid(length) {
  const lengthModifier = Math.pow(10, length);
  return Math.floor(lengthModifier + Math.random() * 9 * lengthModifier);
}

const isUndefinedOrNull = (v) => {
  if (v === undefined || v === null) {
    return true;
  }

  return false;
};

const numberWithCommas = (n) => {
  if (isUndefinedOrNull(n)) {
    return;
  }
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const isEmptyString = (v) => {
  if (v === undefined || v === null || v === "") {
    return true;
  }

  return false;
};

const isPropertyStatusMatchable = (obj) => {
  if (obj === null || obj === undefined) {
    return false;
  } else {
    return (
      obj.status === "PS_AVAILABLE_MATCH" || obj.status === "PS_BOOKED_MATCH"
    );
  }
};

const formatArrayString = (arr) => {
  return arr.toString().replace(/,/g, ", ");
};

const filterNull = (obj) => {
  if (obj === null || obj === undefined) {
    return;
  }

  return __.pickBy(obj, (value, key) => {
    if (value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  });
};

const fieldToHelpTextData = {
  "org.name": "Name of the organization",
  "org.legal_name": "Include suffix (Inc., Ltd., etc.)",
  "org.address": "Legal address for the company",
  "org.broker":
    "Is the <org_type> also working with a broker to fill their space(s)",
  "org.source_inbound":
    "Inbound: they contacted us first; Outbound: we contacted them first",

  is_primary_contact_tour_contact:
    "Who will coordinate tours with us (if different than Primary Contact)",
  is_primary_contact_signature_contact:
    "Who is the signing authority for the company (if different than Primary Contact)",

  ops_owner: "Who owns this relationship on the Operations Team",
  sales_owner: "Who owns this relationship on the Sales Team",

  total_size:
    "Total square footage of the property, including all space used by the host and other guests",
  total_tenants:
    "Number of companies currently using the space, including the host",
  multiple_guests:
    "Is the Host open to having more than one Guest in the space",
  host_lease_end_type:
    "When does the current lease on the property end (if applicable)",
  host_lease_end_excluded:
    "Exlude from the lease end automation activity creation",
  broker: "Is the Host also working with a broker to fill this specific space",
  house_rules: "Any rules the host has regarding the property",
  twentyfourseven: "Will the guest be able to access their space at any time",

  "config.securable":
    "Is the guest able to secure their space and their belongings",
  "config.list_fee_basis":
    "Monthly. If set to sqft, the list fee is calculated based on size below.",
  "config.list_fee": "The price paid to the host",
  "config.tmi_included":
    "Are property taxes, maintenance, and insurance included in the place fee",
  "config.tmi_cost":
    "An estimate of how much the guest will pay for tax, maintenance, and insurance",
  "config.utilities_included": "Are utilities included in the place fee",
  "config.deposit_months":
    "How many months of rent is required as security deposit?",
  "config.deposit_amount":
    "What dollar amount is required as security deposit?",
  "config.name": "Descriptive name for this listing for internal use only",
  "config.title": "The publicly displayed title for this listing",
  "config.description":
    "The main text describing the space that is published in the listing",
  "config.tour_url": "Youtube tour URL to embed on placeholder.com",
  "config.brochure":
    "Either a custom brochure the user has uploaded or the generated one from empire",
  "config.status": "The status of this property config",
  "self_listing.user.email":
    "The empire listing owner of a self listing, edit this feild and check if a user exists to switch the owner",
  "config.property_lost.reason": "Reason why property was lost",
  "listing_extra.place_fee_override_lp_placeholder":
    "Manual override of place fee for this listing (use with caution!)",
  "listing_extra.place_fee_override_lp_kijiji":
    "Manual override of place fee for this listing (use with caution!)",
  "listing_extra.place_fee_override_lp_spacelist":
    "Manual override of place fee for this listing (use with caution!)",
  "listing_extra.alternate_address_lp_spacelist":
    "Replace the real property address with this address on the Spacelist listing",

  space_type: "The type of the space",
  warehouse_usage: "How is this space currently being used",
  square_footage_min: "Minimum square footage a guest can use",
  square_footage_max: "Maximum square footage a guest can use",
  ceiling_clearance_feet: "Ceiling height in the guest's space in feet",
  ceiling_clearance_inches: "Ceiling height in the guest's space in inches",
  temperature_ac: "Is the space air conditioned",
  temperature_refrig: "Is there refrigerated space",
  temperature_freeze: "Is there freezer space",
  power_amps: "Total amount of power (in amps) available to the guest",
  power_3phase: "Is the power three-phase",
  permitted_food: "Food manufacturing or other uses related to food",
  permitted_auto: "Automotive repair, autobody shop, etc",
  permitted_cannabis:
    "Anything related to growing cannabis or manufacture of cannabis-related products",
  permitted_sport_studio:
    "Sports and recreational activity that can be operated with spaces mostly as-is, e.g. yoga",
  permitted_sport_complex:
    "Sports and recreational activity that have complex infrastructure and zoning requirements e.g. courts, facilities",
  permitted_highvol:
    "Involves frequent and high-volume loading and unloading of material",
  permitted_manufacture:
    "Manufacturing involving heavy loud machinery or producing significant noise or dust",
  setup_fee: "Fee paid by the guest on move in",
  setup_fee_refundable:
    "Is the setup fee refunded to the guest when they move out",
  docks_available:
    "Are there truck-level loading docks for the exclusive use of the guest",
  docks_53:
    "Do the exclusive truck-level loading docks accommodate 53 foot trailers",
  docks_leveler:
    "Do the exclusive truck-level loading docks include leveler equipment to facilitate loading/unloading",
  drivein_doors_available:
    "Are there drive-in doors that are for the exclusive use of the guest",
  is_office_size_included:
    "Yes means that the space being listed and the List Fee includes this office space; No means the office size is a separate feature and cost",
  private_office: "Is there office space for the exclusive use of the guest",
  private_office_sqft: "Total size of the exclusive office space",
  private_office_furniture: "Is the exclusive office space furnished",
  racking: "Are there existing racks in the space for the guest's use",
  heating: "Is the space heated",

  "warehouse.docks_available":
    "Are there truck-level loading docks that are shared with the host or other guests",
  "warehouse.docks_53":
    "Do the shared truck-level loading docks accommodate 53 foot trailers",
  "warehouse.docks_leveler":
    "Do the shared truck-level loading docks include leveler equipment to facilitate loading/unloading",
  "warehouse.drivein_doors_available":
    "Are there drive-in doors that are shared with the host or other guests",
  "warehouse.forklifts_available":
    "Are there forklifts available for the guest to use",
  "warehouse.parking_trailer":
    "Is there trailer parking available for the guest's use",
  "warehouse.parking_trailer_type":
    "What is the surface of the trailer parking",
  "warehouse.warehouse_usage": "How does the guest plan on using the space",
  "warehouse.usage_food": "Food manufacturing or other uses related to food",
  "warehouse.usage_auto": "Automotive repair, autobody shop, etc",
  "warehouse.usage_cannabis":
    "Anything related to growing cannabis or manufacture of cannabis-related products",
  "warehouse.usage_customer":
    "Will the guest's customers be visiting the space",
  "warehouse.usage_sport":
    "Sports training, fitness and related recreational uses",
  "warehouse.usage_highvol":
    "Involves frequent and high-volume loading and unloading of material",
  "warehouse.securable":
    "Guest needs to be able to secure their space and their belongings",
  "warehouse.size_min": "Minimum square footage guest requires",
  "warehouse.size_max": "Maximum square footage guest is willing to consider",
  "warehouse.temperature_ac": "Guest needs the space to be air conditioned",
  "warehouse.temperature_refrig": "Guest needs refrigeration space",
  "warehouse.temperature_freeze": "Guest needs freezer space",
  "warehouse.power_highvoltage":
    "Guest needs industrial grade power, e.g. >120V, 3 phase, etc",
  "warehouse.bays":
    "Total number of loading bays (truck-level docks + drive-in doors) needed by the guest",
  "warehouse.bays_docks":
    "At least some of the loading bays must be truck-level docks",
  "warehouse.bays_drivein":
    "At least some of the loading bays must be drive-in doors",
  "warehouse.bays_53": "The loading bays must support 53 foot trailers",
  "warehouse.bays_exclusive": "Guest is not willing to share loading bays",
  "warehouse.building_zoning":
    "Presume zoning applies to all properties/spaces",
  "warehouse.square_footage_min": "Min amount a Guest can rent",
  "warehouse.ceiling_feet":
    "Minimum ceiling height required by the guest for the warehouse in feet and inches",
  "warehouse.ceiling_inches": "Minimum ceiling height required (in)",

  "officeConfig.office_type": "The kind of guest space",
  "officeConfig.capacity_min":
    "Minimum number of people that can fit in the guest space",
  "officeConfig.capacity_max":
    "Maximum number of people that can fit in the guest space",
  "officeConfig.square_footage": "The square footage usable by the guest",
  "officeConfig.furniture": "Does the space include furniture",
  "officeConfig.furniture_cost":
    "The extra amount paid by the guest for furniture, if applicable",
  "officeConfig.setup_fee": "Fee paid by the guest on move in",
  "officeConfig.setup_fee_refundable":
    "Is the setup fee refunded to the guest when they move out",
  "officeConfig.meeting_rooms_available":
    "Does the space include meeting rooms for the exclusive use of the guest",

  "office.building_name":
    "The name of the building containing this office, if applicable",
  "office.building_notes":
    "Special information about the building containing this office",
  "office.meeting_rooms_available":
    "Does the space have shared meeting rooms available for guest use",
  "office.meeting_rooms_charge_type_id":
    "Per Hour: all shared meeting room time is paid, Credits: guest gets a certain number of hours free per month and pays after that",
  "office.common_area":
    "Is there a recreational or other common use space for the guest to use alongside other guests",
  "office.waiting_area": "Is there a reception area where visitors can wait",
  "office.receptionist":
    "Is the office entrance attended by a receptionist that can assist with visitors",
  "office.mailbox":
    "Is there a service for assisting the guest with receiving mail",
  "office.capacity":
    "How many people does the guest need to fit into the space",
  "office.square_footage_type":
    "What is the ideal square footage of the space for the exclusive use of the guest",
  "office.square_footage_min":
    "What is the minimum square footage the guest will consider",
  "office.square_footage_max":
    "What is the maximum square footage the guest will consider",
  "office.securable":
    "Guest needs to be able to secure their space and their belongings",
  "office.pets": "Guest has pets they will bring to the space",
  "office.meeting_rooms": "Minimum number of meeting rooms the guest requires",
  "office.meeting_rooms_exclusive":
    "At least some of the meeting rooms must be for the exclusive use of the guest",

  name: "Internal-only description of this request",

  warehouse_types: "Check all options that the guest is open to",
  office_types: "Check all options that the guest is open to",
};

export const fieldToHelpText = (field) => {
  if (!field) return;
  const parts = field.split ? field.split(".") : [];
  let searchField = "";
  for (let i = parts.length - 1; i >= 0; --i) {
    searchField = parts[i] + (searchField !== "" ? "." : "") + searchField;
    let helpText = fieldToHelpTextData[searchField];
    if (helpText !== undefined) return helpText;
  }
  return undefined;
};

const arrToString = (arr) => {
  return arr.join(", ");
};

const formatCurrency = (n) => {
  return "$" + numberWithCommas(parseFloat(n).toFixed(2));
};

const filterFieldProps = (props) => {
  const fieldProps = Object.assign({}, props);
  delete fieldProps.isSideNote;
  delete fieldProps.sideNoteNamespace;
  delete fieldProps.indent;
  delete fieldProps.noLabel;
  delete fieldProps.smallLabel;
  delete fieldProps.isMultiConfig;
  delete fieldProps.static;
  delete fieldProps.hide;
  delete fieldProps.hideInput;
  delete fieldProps.isDollarScale;
  delete fieldProps.noNull;
  delete fieldProps.helpTextModifier;
  delete fieldProps.labelWidth;
  delete fieldProps.containerWidth;
  delete fieldProps.placeholder;
  delete fieldProps.postInputLabel;
  delete fieldProps.className;
  return fieldProps;
};

const placeFeeDetailed = (feeData) => {
  if (!feeData) return <></>;
  const lfbWords = (lfb) => {
    for (let basis of EnumListFeeBasisWarehouse)
      if (basis.value === lfb) return basis.name;
    return "";
  };
  const priceForSize = (price, sqft) => {
    return `${formatCurrency(price)} (for ${numberWithCommas(
      sqft
    )} square feet)`;
  };
  let result = [];
  if (
    feeData.feeMin !== 0 &&
    feeData.squareFeet !== 0 &&
    feeData.squareFeetMin !== 0 &&
    feeData.squareFeet !== feeData.squareFeetMin
  ) {
    result.push(`${priceForSize(feeData.feeMin, feeData.squareFeetMin)}`);
    result.push(`${priceForSize(feeData.fee, feeData.squareFeet)}`);
  } else {
    result.push(`${formatCurrency(feeData.fee)}`);
  }
  if (feeData.feeSFMo !== 0) {
    result.push(`${formatCurrency(feeData.feeSFMo)} ${lfbWords(LFB_SQFT_MO)}`);
  }
  if (feeData.feeSFYr !== 0) {
    result.push(`${formatCurrency(feeData.feeSFYr)} ${lfbWords(LFB_SQFT_YR)}`);
  }
  return (
    <div>
      {result.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
};

const redirectToAuthenticatedApp = (
  redirectState,
  defaultRedirectURL,
  history
) => {
  // check redirect state and build a url to send the user to next
  let redirectURL;
  if (redirectState !== undefined) {
    redirectURL = redirectState.srcPath;
    if (redirectState.srcQry !== undefined) redirectURL += redirectState.srcQry;
  } else {
    redirectURL = defaultRedirectURL;
  }
  history.push(redirectURL);
};

const getUUID = () => {
  let fourAlphaNumeric = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  //return id of format 'aaaaaaaa'-'aaaa'-'aaaaaaaa'
  return (
    fourAlphaNumeric() +
    fourAlphaNumeric() +
    "-" +
    fourAlphaNumeric() +
    "-" +
    fourAlphaNumeric() +
    fourAlphaNumeric()
  );
};

const propertyHasValues = (data) => {
  // Function used to see if the location section of the property has information by looking at the property object
  var hasValue = false;
  for (const key in data) {
    switch (key) {
      case "address":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "city":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "province":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "postal":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "cache_long":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "cache_lat":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      case "country":
        hasValue = !isUndefinedOrNull(data[key]);
        break;
      default:
        break;
    }
  }
  return hasValue;
};

const scrollWithinView = (id, offset = 0) => {
  const element = document.getElementById(id)?.getBoundingClientRect();
  if (element)
    window.scrollTo(window.scrollX, element.top - offset + window.scrollY);
};

const isUSCountry = (value) => {
  return value === COUNTRY_US;
};

const statusIsLeaseEndAvailable = (status) => {
  return (
    status === PS_UNAVAILABLE ||
    status === PS_BOOKED_NOMATCH ||
    status === PS_BOOKED_MATCH
  );
};

export {
  convertPermissionsToGQLInput,
  convertPermissionsFromGQLResponse,
  buildPropertyName,
  checkPassword,
  getFileType,
  makeid,
  isUndefinedOrNull,
  numberWithCommas,
  isEmptyString,
  isPropertyStatusMatchable,
  formatArrayString,
  filterNull,
  arrToString,
  formatCurrency,
  filterFieldProps,
  placeFeeDetailed,
  redirectToAuthenticatedApp,
  getUUID,
  propertyHasValues,
  cloneConfiguration,
  initializeEmptyProperty,
  scrollWithinView,
  cloneRequest,
  tenantRequestParent,
  objectListToStringNames,
  findErrorIndexes,
  addDisabledStyle,
  findErrorsInRequests,
  buildToolTip,
  getEnumName,
  getLengthHeader,
  prepareIdForAdding,
  createDeleteConfig,
  showInputErrorStyle,
  isUSCountry,
  createConfiguration,
  statusIsLeaseEndAvailable,
};
