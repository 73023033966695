import gql from "graphql-tag";

export const ORG_FIELDS = gql`
  fragment OrgFields on Organization {
    org_id
    name
    legal_name
    address
    city
    province
    postal
    country
    industry
    website
    broker
    broker_nb
    source_inbound
    source_category
    source_channel
    source_nb
    notes
    pipedrive_id
  }
`;

export const ORG_DETAIL_FIELDS = gql`
  fragment OrgDetailFields on OrgDetails {
    name
    type
    address
    owner_name
    guest_type
    host_type
  }
`;

export const LISTING_UPLOAD_FIELDS = gql`
  fragment ListingUploadFields on ListingUploadInfo {
    upload_id
    created_at
    completed_at
    links
    files {
      handle
      filetype
    }
  }
`;

export const ORG_PERSON_FIELDS = gql`
  fragment OrgPersonFields on OrgPersonDetails {
    id
    firstname
    lastname
    contact_type
    phone_numbers {
      contact_type
      contact_val
      contact_label
      contact_verified
    }
    emails {
      contact_type
      contact_val
      contact_label
      contact_verified
    }
    preffered_method
  }
`;

export const PERSON_FIELDS = gql`
  fragment PersonFields on Person {
    person_id
    org {
      ## todo remove org fields
      org_id
      name
      legal_name
    }
    firstname
    lastname
    title
    emails {
      person_id
      contact
      category
      contact_type
      primary_contact
      seq
    }
    phones {
      person_id
      contact
      category
      contact_type
      primary_contact
      seq
    }
    pipedrive_id
    preferred_contact
    notes
  }
`;

export const ORG_CONTACT_FIELDS = gql`
  ${PERSON_FIELDS}
  fragment OrgContactFields on OrgContact {
    contact_type
    person {
      ...PersonFields
    }
    purpose
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    user_id
    firstname
    lastname
    email
  }
`;

export const ORG_OWNER_FIELDS = gql`
  ${USER_FIELDS}
  fragment OrgOwnerFields on OrgOwner {
    owner_type
    owner {
      ...UserFields
    }
  }
`;

export const GUEST_LIST_FIELDS = gql`
  fragment GuestListFields on GuestListResponse {
    request_id
    org_id
    name
    capacity
    size_min
    size_max
    temperature_ac
    temperature_refrig
    temperature_freeze
    budget
    request_name
    term_min
    term_max
    active
    firstname
    lastname
    date_modified
    property_type
    office_types
    warehouse_types
  }
`;

export const TENANT_REQUEST_FEILDS = gql`
  fragment RequestFields on AllRequestInfo {
    request_id
    ## general request fields
    property_type
    country
    name
    location_nb
    budget
    budget_nb
    term_min
    term_max
    term_nb
    movein
    movein_nb
    twentyfourseven
    twentyfourseven_nb
    active
    movein_notice
    created_at
    notes
    parking_car
    parking_car_number

    ## warehouse fields
    usage_nb
    usage_food
    usage_auto
    usage_cannabis
    usage_customer
    usage_sport_complex
    usage_sport_studio
    usage_highvol
    usage_manufacture
    securable
    ceiling
    size_min
    size_max
    warehouse_usage
    temperature_ac
    temperature_refrig
    temperature_freeze
    power_amps
    power_highvoltage
    bays
    bays_docks
    bays_drivein
    bays_exclusive
    bays_53
    office
    office_sqft
    warehouse_types
    locations {
      latitude
      longitude
    }
  }
`;

export const LISTING_FIELDS = gql`
  fragment ListingFields on Listing {
    providers
    photos
    floorplans
    extras {
      provider
      item
      value_int
      value_float
      value_string
      value_bool
    }
  }
`;

export const EMPIRE_USER_FIELDS = gql`
  fragment EmpireUserFields on EmpireUser {
    id
    first_name
    last_name
    email
    ext_id
  }
`;

export const CONFIG_FIELDS = gql`
  ${LISTING_FIELDS}
  ${EMPIRE_USER_FIELDS}
  fragment ConfigFields on AllConfigInfo {
    config_id
    name
    title
    description
    tour_url
    space_type
    brochure
    list_fee_basis
    list_fee
    tmi_cost_basis
    tmi_cost
    tmi_included
    utilities_included
    deposit
    deposit_override
    deposit_host
    term_min
    term_max
    term_end
    availability
    availability_notice
    securable
    pipedrive_product_id
    trackcode
    status
    office_types
    office
    is_office_size_included
    office_sqft

    ## warehouse fields
    warehouse_type
    warehouse_usage
    square_footage_min
    square_footage_max
    setup_fee_cost
    setup_fee_refundable
    ceiling_clearance
    power_amps
    power_3phase
    temperature_ac
    temperature_refrig
    temperature_freeze
    shared_docks
    shared_docks_53
    shared_docks_leveler
    shared_drivein_doors
    docks
    docks_53
    docks_leveler
    drivein_doors
    heating
    permitted_food
    permitted_auto
    permitted_cannabis
    permitted_customer
    permitted_sport_complex
    permitted_sport_studio
    permitted_highvol
    permitted_manufacture
    created_at

    self_listing {
      deal_id
      comment
      user {
        ...EmpireUserFields
      }
    }

    listable {
      result
      reasons
    }

    listings {
      ...ListingFields
    }
  }
`;

export const PROPERTY_PHOTO_FIELDS = gql`
  fragment PropertyPhotoFields on PropertyPhotoResponse {
    document_id
    document_type
    file_type
    handle
    handle_public
    url
    owner_table
    owner_id
    seq
    configs
    description
  }
`;

export const SEARCH_PAGE_INFO = gql`
  fragment SEARCH_INFO on SearchPageInfo {
    index
    size
    isMoreItems
  }
`;

export const PROPERTY_FIELDS = gql`
  ${PROPERTY_PHOTO_FIELDS}
  ${CONFIG_FIELDS}
  fragment PropertyFields on AllPropertyInfo {
    property_id
    ## general property fields
    property_type
    country
    address
    unit
    city
    province
    postal
    cache_lat
    cache_long
    total_size
    total_tenants
    multiple_guests
    broker
    host_lease_end
    host_lease_end_excluded
    twentyfourseven
    house_rules
    notes
    lease_type

    ## warehouse fields
    building_zoning
    bathrooms
    parking_car
    parking_car_number
    parking_car_cost
    created_at

    photos {
      ...PropertyPhotoFields
    }
    floorplans {
      ...PropertyPhotoFields
    }

    exclusives {
      start
      end
      exclusivity
      notes
    }

    ## config level info
    configs {
      ...ConfigFields
    }
  }
`;
