import { useState, useContext, useEffect } from "react";
import { OnChange } from "react-final-form-listeners";
import { Grid } from "@mui/material";
import { useFormState } from "react-final-form";

import FormTree from "components/FormTree";
import { configsToOptions } from "services/forms/helpers";
import { TenantDetails, TenantWarehouseConfig, Location } from "./FormSections";
import { FormContext } from "contexts";
import {
  findErrorsInRequests,
  isEmptyString,
  tenantRequestParent,
} from "services/utils";
import NotesSection from "components/NotesSection";
import { TenantRequestInfoProps } from "interfaces/tenantRequestInfo";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { FormCommand } from "enums/formEnums";
import OptionalSpaceRequirements from "./FormSections/OptionalSpaceRequirements";
import WarehouseRequestDetails from "./FormSections/WarehouseRequestDetails";

export default function TenantRequestInfo({
  tenantRequestInfo,
  mutators,
}: TenantRequestInfoProps): JSX.Element {
  const { errors: formErrors } = useFormState();
  const { setFormCommand, formCommand, setDeleteEntities } =
    useContext(FormContext);

  const errors = findErrorsInRequests(
    formErrors?.requests ?? null,
    tenantRequestInfo?.length
  );

  const [configIndex, setConfigIndex] = useState<number | null>(null);
  const [isParentSelected, setIsParentSelected] = useState<boolean>(false);

  const setCurrentChildIndex = (index) => {
    setConfigIndex(index);
  };

  const addLocation = (addedLocation) => {
    mutators.addLocation({ addedLocation, configIndex });
  };

  const removeLocation = (removedLocation) => {
    mutators.removeLocation({ removedLocation, configIndex });
  };

  const deleteConfig = () => {
    setConfigIndex(0);
    mutators.deleteRequestConfig(configIndex, setDeleteEntities);
  };

  const deleteRequest = () => {
    mutators.deleteRequest(setDeleteEntities);
  };

  const addRequest = (name, cloneId, existingName) => {
    mutators.addRequestConfiguration({
      name,
      setConfigIndex,
      configIndex,
      cloneId,
    });

    if (!isEmptyString(existingName)) {
      mutators.setRequestName({
        name: existingName,
        configIndex: 0,
      });
    }

    setConfigIndex(tenantRequestInfo.length);
  };

  useEffect(() => {
    if (formCommand === FormCommand.CREATE_REQUEST) {
      setIsParentSelected(true);
      setFormCommand(FormCommand.IDLE);
      setConfigIndex(0);
    }
  }, [formCommand, setFormCommand, tenantRequestInfo?.length]);

  const config = configIndex != null ? tenantRequestInfo[configIndex] : null;
  const requestName = `requests[${configIndex}]`;

  return (
    <>
      <FormTree
        configs={tenantRequestInfo.map((request) => ({
          config_id: request.request_id,
          ...request,
        }))}
        parentID={null}
        errors={errors}
        containerId={0}
        onAddConfiguration={addRequest}
        parentName={tenantRequestParent(
          tenantRequestInfo.length,
          tenantRequestInfo[0]?.created_at,
          tenantRequestInfo[0]?.name
        )}
        childNames={configsToOptions(tenantRequestInfo, true)}
        currentChildIndex={configIndex}
        setCurrentChildIndex={setCurrentChildIndex}
        type={OrganizationChildrenTypes.TenantRequest}
        setIsParent={setIsParentSelected}
        isParent={isParentSelected}
        deleteConfig={deleteConfig}
        deleteParent={deleteRequest}
      />
      {configIndex !== null && (
        <>
          <OnChange name={`${requestName}.term_min`}>
            {(curr, previous) => {
              if (tenantRequestInfo[configIndex].term_type === "1") {
                mutators.setTermMaxRequest(curr, configIndex);
              }
            }}
          </OnChange>

          <Grid container spacing={2} marginTop={"1px"}>
            <Grid item xs={9} container spacing={2}>
              <TenantDetails requestName={requestName} />
              <Location
                configIndex={configIndex}
                request={tenantRequestInfo[configIndex]}
                addLocation={addLocation}
                removeLocation={removeLocation}
                requestName={requestName}
              />
              <TenantWarehouseConfig requestName={requestName} />
              <Grid item md={4} xs={12}>
                <Grid container spacing={5}>
                  <OptionalSpaceRequirements requestName={requestName} />
                  <WarehouseRequestDetails
                    requestName={requestName}
                    dealId={config?.self_listing?.deal_id}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <NotesSection fieldName={`${requestName}.notes`} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
