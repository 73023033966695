import formatString from "format-string-by-pattern";
import _ from "lodash";

import { isUndefinedOrNull } from "../utils";

import { WRH_SHARED_SPACE, EMPIRE_URL, CREXI_URL } from "../constants";

const isSharedProperty = (warehouseType) => {
  return WRH_SHARED_SPACE === warehouseType;
};

function postLoadFormatInRentAndCost(obj, namespace, costPostfix) {
  if (obj[namespace] && obj[namespace + costPostfix] != null) {
    obj[namespace + costPostfix] = parseFloat(obj[namespace + costPostfix]);

    if (obj[namespace + costPostfix] === 0) {
      obj[namespace + "_in_rent"] = true;
    } else if (obj[namespace + costPostfix] > 0) {
      obj[namespace + "_in_rent"] = false;
    }
  }
}

function preSaveFormatInRentAndCost(obj, namespace, costPostfix) {
  // no need to convert float to int here, it's converted on BE's GQL layer
  // no decimal => fill in the decimal so that $100 -> $100.00 ? => no need! BE GQL CurrencyAmount handles it!
  if (obj[namespace + "_in_rent"] == null) {
    obj[namespace + costPostfix] = null;
  }

  if (obj[namespace + "_in_rent"] === true) {
    obj[namespace + costPostfix] = 0;
  }
  delete obj[namespace + "_in_rent"];
}

function postLoadFormatSetupFee(obj) {
  // Set "Setup Fee Charged to Guest" yes/no value from the cost.
  let setupFeeCost = obj["setup_fee_cost"];
  if (!isUndefinedOrNull(setupFeeCost)) {
    if (parseFloat(setupFeeCost) > 0) {
      obj["setup_fee"] = true;
    } else {
      obj["setup_fee"] = false;
    }
  }
  // when "setup_fee_cost" is undefined or null, "setup_fee" should be undefined
  // so that the yes/no checkbox is unselected.
}

function preSaveFormatSetupFee(obj) {
  if (isUndefinedOrNull(obj["setup_fee"])) {
    // when setup_fee is unchecked, we should reset subsequent fields.
    delete obj["setup_fee_cost"];
    delete obj["setup_fee_type"];
    delete obj["setup_fee_refundable"];
  } else if (
    !isUndefinedOrNull(obj["setup_fee"]) &&
    obj["setup_fee"] === false
  ) {
    // when setup_fee is explicitly set to "false", make sure the cost is 0.
    obj["setup_fee_cost"] = 0;
    delete obj["setup_fee_type"];
    delete obj["setup_fee_refundable"];
  }

  delete obj["setup_fee"];
}

function postLoadFormatAvailableNumber(obj, namespace) {
  if (obj[namespace] !== undefined) {
    if (obj[namespace] === 0) {
      obj[namespace + "_available"] = false;
    }
    if (obj[namespace] > 0) {
      obj[namespace + "_available"] = true;
    }
  }
}

function preSaveFormatAvailableNumber(obj, namespace) {
  if (isUndefinedOrNull(obj[namespace + "_available"])) {
    obj[namespace] = null;
  } else {
    if (obj[namespace + "_available"] === false) {
      obj[namespace] = 0;
    }
  }
  delete obj[namespace + "_available"];
}

function convertGQLToFormWarehouse(property) {
  postLoadFormatInRentAndCost(property, "parking_car", "_cost");
  postLoadFormatAvailableNumber(property, "docks");
  postLoadFormatAvailableNumber(property, "drivein_doors");

  if (property.postal != null) {
    property.address_known = true;
  }

  if (property.host_lease_end === "2006-01-02") {
    property.host_lease_end_type = "2"; // month to month
  } else {
    property.host_lease_end_type = "1"; // specific date
  }
}

function doesSizeHaveRange(square_footage_min, square_footage_max) {
  if (square_footage_min == null || square_footage_min <= 0) return null;

  return square_footage_min !== square_footage_max;
}

function convertGQLToFormConfig(config) {
  // term
  if (config.term_min && config.term_max) {
    if (config.term_min === config.term_max) {
      config.term_type = "1";
    } else {
      config.term_type = "2";
    }
  }

  if (config.availability === undefined || config.availability === null) {
    config.availability_type = "1";
  } else {
    config.availability_type = "2";
  }

  // Security Deposit
  if (
    !isUndefinedOrNull(config) &&
    !isUndefinedOrNull(config.deposit_override)
  ) {
    // Convert bool override to string for radio button input
    config.deposit_override = config.deposit_override.toString();
  }

  if (
    !isUndefinedOrNull(config.deposit) &&
    !isUndefinedOrNull(config.deposit_override)
  ) {
    if (config.deposit_override === "false") {
      config.deposit_months = config.deposit;
    }
    if (config.deposit_override === "true") {
      config.deposit_amount = config.deposit / 100; // Cents to dollars figure
    }
    delete config.deposit;
  }

  if (!isUndefinedOrNull(config) && !isUndefinedOrNull(config.deposit_host)) {
    // Convert bool override to string for radio button input
    config.deposit_host = config.deposit_host.toString();
  }
}

function convertGQLToFormWarehouseConfig(config) {
  postLoadFormatAvailableNumber(config, "docks");
  postLoadFormatAvailableNumber(config, "drivein_doors");
  postLoadFormatAvailableNumber(config, "shared_docks");
  postLoadFormatAvailableNumber(config, "shared_drivein_doors");

  postLoadFormatSetupFee(config);

  config.has_range_of_space = doesSizeHaveRange(
    config.square_footage_min,
    config.square_footage_max
  );

  const clearance = convertCeilingClearanceToFeetAndInches(
    config.ceiling_clearance
  );

  config.ceiling_clearance_feet = clearance.feet;
  config.ceiling_clearance_inches = clearance.inches;

  delete config.ceiling_clearance;
}

function convertCeilingClearanceToFeetAndInches(ceilingClearance) {
  const clearance = {
    feet: null,
    inches: null,
  };

  if (isUndefinedOrNull(ceilingClearance)) return clearance;

  clearance.feet = _.floor(ceilingClearance / 12);
  clearance.inches = ceilingClearance - clearance.feet * 12;

  return clearance;
}

const calculateCeilingClearance = (feet, inches) => {
  if (isUndefinedOrNull(feet) && isUndefinedOrNull(inches)) return null;

  const parsedFeet = feet ?? 0;
  const parsedInches = inches ?? 0;

  return parsedFeet * 12 + parsedInches;
};

function convertFormToGQLWarehouse(warehouse) {
  preSaveFormatInRentAndCost(warehouse, "parking_car", "_cost");

  if (!isFieldSelected(warehouse.parking_car)) {
    delete warehouse.parking_car_number;
  }

  if (warehouse.host_lease_end_type === "2") {
    // month to month
    // set to first unix time so automation runs every month
    warehouse.host_lease_end = "2006-01-02";
  }
  delete warehouse.host_lease_end_type;
  delete warehouse.address_known;
}

function convertFormToGQLConfig(config) {
  if (config.ph_service_fee) {
    delete config.ph_service_fee;
  }

  if (config?.self_listing) {
    delete config.self_listing.user;
  }

  if (config?.brochure) {
    delete config.brochure;
  }

  if (config.term_type) {
    if (config.term_type === "1") {
      config.term_max = config.term_min;
    }
    delete config.term_type;
  }
  if (config.availability_type) {
    if (config.availability_type === "1") {
      delete config.availability;
    }
    delete config.availability_type;
  }

  // Security Deposit
  if (
    !isUndefinedOrNull(config) &&
    !isUndefinedOrNull(config.deposit_override)
  ) {
    let boolean = config.deposit_override === "true";
    config.deposit_override = boolean;
  }

  if (
    !isUndefinedOrNull(config.deposit_months) ||
    !isUndefinedOrNull(config.deposit_amount)
  ) {
    if (!isUndefinedOrNull(config.deposit_months) && !config.deposit_override) {
      config.deposit = config.deposit_months;
    }
    if (!isUndefinedOrNull(config.deposit_amount) && config.deposit_override) {
      config.deposit = config.deposit_amount * 100; // save in cents
    }
    delete config.deposit_months;
    delete config.deposit_amount;
  } else {
    config.deposit_override = null; // do not save override if # of months or amount is not specified
  }

  if (!isUndefinedOrNull(config) && !isUndefinedOrNull(config.deposit_host)) {
    let boolean = config.deposit_host === "true";
    config.deposit_host = boolean;
  }

  // Remove all trailing newlines (rendered by quill as '<p><br></p>') from the
  // HTML generated by quill. When this trailing newline is received by quill,
  // it is ignored, so final form will consider the form state to be different,
  // requiring a double save for configs which were saved with descriptions
  // ending with trailing newlines.
  if (config.description)
    config.description = config.description
      .replace(/(<p><br><\/p>)*$/g, "") // trailing newlines
      .replace(/<p>\s+/g, `<p>`) // trailing spaces in p tag content
      .replace(/\s+<\/p>/g, `</p>`)
      .replace(/<li>\s+/g, `<li>`) // trailing spaces in li tag content
      .replace(/\s+<\/li>/g, `</li>`);
}

function isFieldSelected(field) {
  return !(field == null || field === false);
}

function preSaveFormatSize(config) {
  const hasRangeOfSpace = config.has_range_of_space;
  if (hasRangeOfSpace == null) {
    delete config.square_footage_max;
    delete config.square_footage_min;
  }

  if (hasRangeOfSpace === false) {
    config.square_footage_max = config.square_footage_min;
  }

  delete config.has_range_of_space;
}

function preSaveFormatOffice(config) {
  const hasOfficeSpace = config.office;
  if (hasOfficeSpace == null || hasOfficeSpace === false) {
    delete config.office_sqft;
    delete config.office_types;
    delete config.is_office_size_included;
  }
}

function convertFormToGQLWarehouseConfig(config) {
  preSaveFormatAvailableNumber(config, "docks");
  preSaveFormatAvailableNumber(config, "drivein_doors");
  preSaveFormatAvailableNumber(config, "shared_docks");
  preSaveFormatAvailableNumber(config, "shared_drivein_doors");

  preSaveFormatSetupFee(config);
  preSaveFormatSize(config);
  preSaveFormatOffice(config);

  config.ceiling_clearance = calculateCeilingClearance(
    config.ceiling_clearance_feet,
    config.ceiling_clearance_inches
  );
  delete config.ceiling_clearance_feet;
  delete config.ceiling_clearance_inches;
}

const convertGQLToFormListingExtra = (listingExtra) => {
  if (!listingExtra) return {};

  let processed = {};
  for (let entry of listingExtra) {
    let fieldVal = null;
    // assuming that at least one of these values must be set
    for (let k of ["value_int", "value_float", "value_string", "value_bool"])
      if (entry[k] !== null) {
        let val = entry[k];
        // if we are receiving a currency item, it should be converted to float
        // for use in frontend
        if (k === "value_int" && entry.item.includes("PlaceFee")) val /= 100;
        fieldVal = val;
        break;
      }
    // fields should be named in the DOM accordingly
    processed[`${entry.item}_${entry.provider.toLowerCase()}`] = fieldVal;
  }
  return processed;
};

const convertFormToGQLListingExtra = (listingExtra) => {
  let processed = [];

  delete listingExtra[EMPIRE_URL];
  delete listingExtra[CREXI_URL];

  // iterate over the object's keys
  for (let k in listingExtra) {
    if (!/.*_lp_.*/.test(k)) continue;
    let extra = {
      provider: k.replace(/^.*_lp/, "lp").toUpperCase(),
      item: k.replace(/_lp_.*$/, ""),
    };
    let value = listingExtra[k];
    switch (typeof value) {
      case "boolean": {
        extra["value_bool"] = value;
        break;
      }
      case "string": {
        extra["value_string"] = value;
        break;
      }
      case "number": {
        // if we are sending a currency item, we convert it to an integer;
        // multiplication by 100 will always be an integer in this case since UI
        // ensures that there are at most 2 decimal places in a currency input;
        let val = extra.item.includes("PlaceFee") ? value * 100 : value;
        if (val % 1 === 0) extra["value_int"] = val;
        else extra["value_float"] = val;
        break;
      }
      default:
    }
    processed.push(extra);
  }
  return processed;
};

function configsToOptions(configs, isRequest = false) {
  try {
    let ret = [];
    configs.forEach((c, index) => {
      ret.push({
        value: index,
        name: c.name,
        id: isRequest ? c.request_id : c.config_id,
      });
    });
    return ret;
  } catch (err) {
    return [];
  }
}

const formatPostalCode = (input) => {
  return formatString("A9A 9A9", input.toUpperCase());
};

const formatZipCode = (input) => {
  return formatString("99999-9999", input);
};

const parseQuery = (queryString) => {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

const isActivelyPublished = (listings) => {
  return !(_.isEmpty(listings) || listings.length === 0);
};

export {
  convertGQLToFormWarehouse,
  convertGQLToFormConfig,
  convertGQLToFormWarehouseConfig,
  convertFormToGQLWarehouse,
  convertFormToGQLConfig,
  convertFormToGQLWarehouseConfig,
  convertGQLToFormListingExtra,
  convertFormToGQLListingExtra,
  configsToOptions,
  formatPostalCode,
  formatZipCode,
  isSharedProperty,
  parseQuery,
  isActivelyPublished,
  convertCeilingClearanceToFeetAndInches,
  calculateCeilingClearance,
};
