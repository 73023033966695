import gql from "graphql-tag";
import * as fragments from "./fragments.js";

const GET_SIGNED_URLS = gql`
  query getSignedURLs($handles: [String!]!) {
    getSignedURLs(handles: $handles)
  }
`;

const APP_CONFIG_QUERY = gql`
  query appconfigs {
    appConfigs {
      menus
      permissions
      mfa_enabled
      pipedrive_connected
      tunings {
        item
        value_string
        value_int
        value_float
        value_bool
      }
      required_request_fields
      required_config_fields
      required_property_fields
      required_listing_fields
      listingProviders {
        location
        values
      }
    }
  }
`;

const USERS_QUERY = gql`
  ${fragments.USER_FIELDS}
  query users {
    users {
      ...UserFields
      auth0_id
    }
  }
`;

const USER_QUERY = gql`
  ${fragments.USER_FIELDS}
  query user($userID: Int!) {
    user(userID: $userID) {
      ...UserFields
      permissions
    }
  }
`;

const HOST_QUERY = gql`
  ${fragments.ORG_FIELDS}
  ${fragments.ORG_CONTACT_FIELDS}
  ${fragments.ORG_OWNER_FIELDS}
  query host($id: Int!) {
    host(orgID: $id) {
      org {
        ...OrgFields
      }
      host {
        org_id
        host_type
      }
      persons {
        ...OrgContactFields
      }
      owners {
        ...OrgOwnerFields
      }
      market
      propertyType
      polymorph_info {
        is_polymorphic
      }
    }
  }
`;

const ALL_INFO_QUERY = gql`
  ${fragments.ORG_DETAIL_FIELDS}
  ${fragments.ORG_PERSON_FIELDS}
  ${fragments.LISTING_UPLOAD_FIELDS}
  ${fragments.TENANT_REQUEST_FEILDS}
  ${fragments.PROPERTY_FIELDS}
  ${fragments.CONFIG_FIELDS}
  query allInfo($infoRequest: AllInfoRequest!) {
    allInfo(infoRequest: $infoRequest) {
      org {
        id
        details {
          ...OrgDetailFields
        }
        persons {
          ...OrgPersonFields
        }
      }
      listing_uploads {
        ...ListingUploadFields
      }
      properties {
        ...PropertyFields
      }
      requests {
        ...RequestFields
      }
    }
  }
`;

const CHANGELOGS_QUERY = gql`
  query changelogs(
    $page: String!
    $objectID: Int!
    $pagination: PaginationInput!
  ) {
    changelogs(page: $page, objectID: $objectID, pagination: $pagination) {
      logs {
        id
        object_type
        change_type
        user_id
        firstname
        lastname
        object_id
        change_time
        name
      }
      offset
    }
  }
`;

const PLACE_FEE_QUERY = gql`
  query placefee(
    $listFee: CurrencyAmount!
    $units: String!
    $capacity: Int
    $squarefeet: Int
    $squarefeetmin: Int
    $furniture: CurrencyAmount
    $province: String!
    $propertyType: String!
    $tunings: [TuningVariableInput!]!
    $configID: Int
  ) {
    placefee(
      listFee: $listFee
      units: $units
      capacity: $capacity
      squarefeet: $squarefeet
      squarefeetmin: $squarefeetmin
      furniture: $furniture
      province: $province
      propertyType: $propertyType
      tunings: $tunings
      configID: $configID
    ) {
      fee
      feeMin
      feeSFMo
      feeSFYr
      listFee
      squareFeet
      squareFeetMin
      serviceFee
      serviceFeeNoRound
      serviceFeePC
      insurance
      insuranceFee
      insuranceFeePC
      furniture
      furnitureFee
      furnitureFeePC
      placeFeeNoRound
      effectiveFeePercent
    }
  }
`;

const CHECK_EMPIRE_USER = gql`
  query findEmpireUser($email: String!) {
    findEmpireUser(email: $email) {
      id
      first_name
      last_name
      email
      ext_id
    }
  }
`;

const PREPARE_ADD_DOCUMENT = gql`
  query prepareAddDocument($key: String!, $public: Boolean!) {
    prepareAddDocument(key: $key, public: $public) {
      privateHandle
      privateUrl
      publicHandle
      publicUrl
    }
  }
`;

const PIPEDRIVE_CONNECT_QUERY = gql`
  query pipedriveConnect {
    pipedriveConnect {
      isConnected
      userID
      name
      email
    }
  }
`;

const SEARCH_QUERY = gql`
  query search($payload: SearchPayload) {
    search(payload: $payload) {
      persons {
        personData {
          id
          orgID
          name
          email
          phone
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      orgs {
        orgData {
          orgID
          name
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      properties {
        propertyData {
          propertyID
          orgID
          address
          type
          configID
          configName
          squareFootage
          price
          title
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      tenant {
        tenantData {
          requestID
          orgID
          requestName
          budget
          squareFootage
          dateModified
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      pageInfo {
        index
        size
        isMoreItems
      }
    }
  }
`;

export {
  SEARCH_QUERY,
  APP_CONFIG_QUERY,
  USERS_QUERY,
  USER_QUERY,
  HOST_QUERY,
  CHANGELOGS_QUERY,
  PLACE_FEE_QUERY,
  PREPARE_ADD_DOCUMENT,
  PIPEDRIVE_CONNECT_QUERY,
  ALL_INFO_QUERY,
  CHECK_EMPIRE_USER,
  GET_SIGNED_URLS,
};
