import { useQuery } from "@apollo/client";
import { APP_CONFIG_QUERY } from "graphql/queries";
import { createContext, useState } from "react";
import {
  DOCS_ImagePublicFormat,
  DOCS_ImagePublicMaxRes,
  FE_DEBOUNCE_FIELD_TIMEOUT,
  FE_DefaultPropertyType,
  PIPEDRIVE_CONNECTED,
} from "services/constants";
import __ from "lodash";
import Loading from "components/Loading";
import { AppConfigResponse, TuningVariable } from "interfaces/queryResponse";
import { AppContextInterface } from "interfaces/appContext";
import { ReactWithChildren } from "interfaces/reactWithChildren";
import jwt_decode from "jwt-decode";
import { Auth0Token } from "interfaces/auth0.interface";

const defaultState: AppContextInterface = {
  feeTunings: [],
  userPermissions: [],
  required_request_fields: [],
  listingProviders: [],
  required_config_fields: [],
  required_property_fields: [],
  required_listing_fields: [],
  isLoaded: false,
  userName: "",
};

export const AppContext = createContext<AppContextInterface>(defaultState);

export function AppState({ children }: ReactWithChildren): JSX.Element {
  const [appState, setAppState] = useState<AppContextInterface>(defaultState);

  const { loading } = useQuery(APP_CONFIG_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data: AppConfigResponse) => {
      const {
        permissions,
        tunings,
        pipedrive_connected,
        required_config_fields,
        required_request_fields,
        listingProviders,
        required_property_fields,
        required_listing_fields,
      } = data.appConfigs;

      const initialState = __.cloneDeep(defaultState);

      // set pipedrive_connected
      localStorage.setItem(
        PIPEDRIVE_CONNECTED,
        (pipedrive_connected === true)?.toString()
      );

      // process Tuning variables
      if (tunings) {
        const feeTunings: TuningVariable[] = [];

        tunings.forEach((tuningVar) => {
          if (tuningVar.item === FE_DefaultPropertyType) {
            // it'll overwrite if already there
            localStorage.setItem(
              "default_property_type",
              tuningVar.value_string
            );
          }
          if (tuningVar.item === FE_DEBOUNCE_FIELD_TIMEOUT) {
            localStorage.setItem(
              "debounce_field_timeout",
              tuningVar.value_int.toString()
            );
          }

          // FEE_ tuning varialbes
          if (__.startsWith(tuningVar.item, "FEE_")) {
            feeTunings.push(tuningVar);
          }

          // DOCS_ tuning varialbes
          if (tuningVar.item === DOCS_ImagePublicMaxRes) {
            localStorage.setItem(
              DOCS_ImagePublicMaxRes,
              tuningVar.value_int.toString()
            );
          }

          if (tuningVar.item === DOCS_ImagePublicFormat) {
            localStorage.setItem(
              DOCS_ImagePublicFormat,
              tuningVar.value_string
            );
          }
        });

        initialState.userPermissions = permissions;
        initialState.isLoaded = true;
        initialState.required_config_fields = required_config_fields;
        initialState.required_request_fields = required_request_fields;
        initialState.listingProviders = listingProviders;
        initialState.required_listing_fields = required_listing_fields;
        initialState.feeTunings = feeTunings;
        initialState.required_property_fields = required_property_fields;

        const idToken = localStorage.getItem("id_token");

        initialState.userName = idToken
          ? (jwt_decode(idToken) as Auth0Token)?.name
          : "";

        setAppState(initialState);
      }
    },
  });

  if (loading && appState.isLoaded) return <Loading />;

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
}
