import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput) {
    createUser(user: $input)
  }
`;

const UPDATE_USER_PERMISSIONS = gql`
  mutation updateUserPermissions($userID: Int!, $permissions: [String!]) {
    updateUserPermissions(userID: $userID, permissions: $permissions)
  }
`;

const UPSERT_USER = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user)
  }
`;

const UPSERT_ALL = gql`
  mutation upsertPropertiesAndRequests($input: AllInfoInput!) {
    upsertPropertiesAndRequests(input: $input) {
      entity_type
      entity_id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: String!) {
    changePassword(password: $input)
  }
`;

const CREATE_MFA_QR_CODE = gql`
  mutation createMfaQRCode {
    createMfaQRCode
  }
`;

const VERIFY_MFA = gql`
  mutation verifyMFA($code: String!) {
    verifyMFA(code: $code)
  }
`;

const DISABLE_MFA = gql`
  mutation disableMFA {
    disableMFA
  }
`;

const DISCONNECT_PIPEDRIVE = gql`
  mutation disconnectPipedrive {
    disconnectPipedrive
  }
`;

const INIT_PIPEDRIVE_CONNECT = gql`
  mutation initPipedriveConnect {
    initPipedriveConnect
  }
`;

const DELETE = gql`
  mutation delete($input: DeleteInput!) {
    delete(input: $input)
  }
`;

const PUBLISH = gql`
  mutation publish($input: PublishInput!) {
    publish(input: $input)
  }
`;

const UNPUBLISH = gql`
  mutation unPublish($input: PublishInput!) {
    unPublish(input: $input)
  }
`;

const CLAIM_LISTING = gql`
  mutation claimForUser($empireID: Int!, $configID: Int!) {
    claimForUser(empireID: $empireID, configID: $configID)
  }
`;

const MARK_BULK_UPLOAD_AS_COMPLETE = gql`
  mutation markUploadComplete($input: UploadCompleteInput!) {
    markUploadComplete(input: $input)
  }
`;

export {
  CREATE_USER,
  UPDATE_USER_PERMISSIONS,
  UPSERT_USER,
  CHANGE_PASSWORD,
  CREATE_MFA_QR_CODE,
  VERIFY_MFA,
  DISABLE_MFA,
  DISCONNECT_PIPEDRIVE,
  INIT_PIPEDRIVE_CONNECT,
  DELETE,
  UPSERT_ALL,
  PUBLISH,
  UNPUBLISH,
  CLAIM_LISTING,
  MARK_BULK_UPLOAD_AS_COMPLETE,
};
