import { Button } from "@mui/material";

import { GridSection } from "components/CardComponents";
import DropdownInput from "components/Forms/DropdownInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import CurrencyInput from "components/Forms/CurrencyInput";
import TextDisplay from "components/Forms/TextDisplay";
import RadioButtonInput from "components/Forms/RadioButtonInput";
import NumberInput from "components/Forms/NumberInput";
import Condition from "components/Forms/Condition";

import {
  EnumListFeeBasisWarehouse,
  EnumTMICostBasis,
  EnumLeaseType,
} from "services/enums";
import { PriceProps } from "interfaces/price";

export default function Price({
  configName,
  propertyName,
  isEditable,
  placeFee,
  effectiveFeePC,
  onClickShowDetail,
  warningMsg,
}: PriceProps): JSX.Element {
  return (
    <GridSection title="Price" md={4} xs={6}>
      <DropdownInput
        label={`Lease Type`}
        name={`${propertyName}.lease_type`}
        options={EnumLeaseType}
      />

      <DropdownInput
        name={`${configName}.list_fee_basis`}
        label={`List Fee Basis`}
        options={EnumListFeeBasisWarehouse}
      />

      <CurrencyInput name={`${configName}.list_fee`} label={`List Fee`} />

      <div className="flex flex-row justify-between">
        <div className="w-48">
          <TextDisplay
            label={`Place Fee`}
            text={warningMsg ? undefined : placeFee ? placeFee : "N/A"}
            warningMsg={warningMsg}
            className="w-full"
          />
        </div>
        {isEditable && (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{ fontSize: "10px" }}
              data-cy={`show-detail-${configName}`}
              disabled={!placeFee || !effectiveFeePC || warningMsg != null}
              onClick={onClickShowDetail}
            >
              Details
            </Button>
          </div>
        )}
      </div>

      <TextDisplay
        label={`Effective Fee %`}
        text={
          warningMsg ? undefined : effectiveFeePC ? `${effectiveFeePC}%` : "N/A"
        }
        warningMsg={warningMsg}
      />

      <br />

      <NullBooleanInput
        name={`${configName}.tmi_included`}
        label={`TMI/CAM Included`}
        defaultValue={true}
        sideNoteNamespace={`${configName}.tmi_cost`}
      />

      <Condition when={`${configName}.tmi_included`} is={false}>
        <DropdownInput
          label={`TMI Cost Basis`}
          name={`${configName}.tmi_cost_basis`}
          options={EnumTMICostBasis}
          indent={2}
        />

        <CurrencyInput
          name={`${configName}.tmi_cost`}
          label={`Estimated TMI`}
          indent={2}
        />
      </Condition>

      <NullBooleanInput
        name={`${configName}.utilities_included`}
        label={`Utilities Included`}
      />

      {/* Setup Form */}

      <NullBooleanInput name={`${configName}.setup_fee`} label={`Setup Fee`} />

      <Condition when={`${configName}.setup_fee`} is={true}>
        <CurrencyInput
          name={`${configName}.setup_fee_cost`}
          label={`Setup Fee`}
          indent={2}
        />

        <NullBooleanInput
          name={`${configName}.setup_fee_refundable`}
          label={`Refundable`}
          indent={2}
        />
      </Condition>

      {/* Security Deposit Form */}

      <RadioButtonInput
        label={`Security Deposit`}
        name={`${configName}.deposit_override`}
        options={[
          { value: "false", name: "Months of Rent" },
          { value: "true", name: "Specific Amount" },
        ]}
        sideNoteNamespace={`${configName}.deposit`}
      />

      <Condition when={`${configName}.deposit_override`} is={"true"}>
        <CurrencyInput
          label={`Deposit Amount`}
          name={`${configName}.deposit_amount`}
          indent={2}
        />
      </Condition>

      <Condition when={`${configName}.deposit_override`} is={"false"}>
        <NumberInput
          label={`Number of Months`}
          name={`${configName}.deposit_months`}
          indent={2}
        />
      </Condition>

      <RadioButtonInput
        label={`Held By `}
        name={`${configName}.deposit_host`}
        options={[
          { value: "false", name: "PlaceHolder" },
          { value: "true", name: "Host" },
        ]}
        indent={2}
      />
    </GridSection>
  );
}
