import { GridSection } from "components/CardComponents";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import TextAreaInput from "components/Forms/TextAreaInput";
import { CardSectionDefaultProps } from "interfaces/cardSection";

export default function PermittedUsage({
  configName,
}: CardSectionDefaultProps): JSX.Element {
  return (
    <GridSection title="Permitted Usage" md={4} xs={6}>
      <NullBooleanInput
        name={`${configName}.permitted_food`}
        label={`Food Preparation`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_auto`}
        label={`Automotive`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_cannabis`}
        label={`Cannabis`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_customer`}
        label={`Customer Facing`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_sport_complex`}
        label={`Sports Complex`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_sport_studio`}
        label={`Sports Studio`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_highvol`}
        label={`High Bay Usage`}
      />

      <NullBooleanInput
        name={`${configName}.permitted_manufacture`}
        label={`Heavy Manufacturing`}
      />

      <TextAreaInput name={`${configName}.permitted_nb`} label={`Notes`} />
    </GridSection>
  );
}
