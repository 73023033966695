import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { OnChange } from "react-final-form-listeners";

import DropdownInput from "components/Forms/DropdownInput";
import TextAreaInput from "components/Forms//TextAreaInput";
import TextInput from "components/Forms/TextInput";
import { GridSection } from "components/CardComponents";
import { Grid, Typography, Box, Button } from "@mui/material";

import { required } from "services/forms/validators";
import { CHECK_EMPIRE_USER } from "graphql/queries";
import { CLAIM_LISTING } from "graphql/mutations";

import {
  EnumPropertyStatus,
  EnumPropertyLostReason,
  EnumSelfListingPropertyStatus,
} from "services/enums";
import { PropertyStatusProps } from "interfaces/propertyStatus";
import { EmpireUserSearchResponse, EmpireUser } from "interfaces/selfListing";
import DateInput from "components/Forms/DateInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import Condition from "components/Forms/Condition";
import RadioButtonInput from "components/Forms/RadioButtonInput";
import TextDisplay from "components/Forms/TextDisplay";

export default function PropertyStatus({
  configName,
  configID,
  propertyName,
  isPropertyChangedToUnavailable,
  selfListing,
  isConfigRejected,
}: PropertyStatusProps): JSX.Element {
  const isSelfListed = selfListing !== null;
  const [emailHasChanged, setEmailChanged] = useState(false);
  const [origEmail, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newUser, setNewUser] = useState<EmpireUser>();
  const [userNoExists, setUserNoExists] = useState("");

  const [userQuery] = useLazyQuery(CHECK_EMPIRE_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: EmpireUserSearchResponse) => {
      const user = data.findEmpireUser;
      if (user && user.id !== 0 && user.ext_id !== 0) {
        setNewUser(user);
      } else {
        setUserNoExists("User does not exist in Empire");
      }
    },
  });

  const [claimListing] = useMutation(CLAIM_LISTING, {
    variables: {
      empireID: newUser?.id,
      configID: configID,
    },
    onCompleted: (data) => {
      // reload the page
      window.location.reload();
    },
  });

  const validateEmail = () => {
    if (newEmail) {
      userQuery({ variables: { email: newEmail } });
    }
  };

  useEffect(() => {
    if (!origEmail && isSelfListed) {
      setEmail(selfListing?.user?.email);
    }
  }, [origEmail, isSelfListed, selfListing?.user?.email]);

  return (
    <GridSection title="Status" md={12} xs={12}>
      <Grid container>
        <Grid item md={4} xs={2}>
          <DropdownInput
            label={`Property Status`}
            name={`${configName}.status`}
            options={
              isSelfListed ? EnumSelfListingPropertyStatus : EnumPropertyStatus
            }
            className="max-w-xs ml-1"
          />
          {isPropertyChangedToUnavailable && (
            <>
              <DropdownInput
                label={`Property Lost Reason`}
                name={`${configName}.property_lost.reason`}
                validate={required}
                options={EnumPropertyLostReason}
              />
              <TextAreaInput
                name={`${configName}.property_lost.notes`}
                label={`Notes`}
              />
            </>
          )}
          {isConfigRejected && (
            <>
              <TextAreaInput
                name={`${configName}.self_listing.comment`}
                label={`Rejection Reason`}
                validate={required}
              />
            </>
          )}
        </Grid>
        {!isSelfListed ? (
          <>
            <Grid item md={3} xs={2}>
              <RadioButtonInput
                name={`${propertyName}.host_lease_end_type`}
                label={`Lease End Date`}
                options={[
                  { value: "1", name: "Specific Date" },
                  { value: "2", name: "Month to Month" },
                ]}
                defaultValue={"1"}
                sideNoteNamespace={`${propertyName}.host_lease_end`}
              />

              <Condition when={`${propertyName}.host_lease_end_type`} is={"1"}>
                <DateInput
                  name={`${propertyName}.host_lease_end`}
                  // Sets max date to a reasonable maximum in the future (assume 80 years from today)
                  max={new Date(
                    Date.UTC(
                      new Date().getFullYear() + 80,
                      new Date().getMonth(),
                      new Date().getDay(),
                      3,
                      0,
                      0
                    )
                  ).toLocaleDateString("en-CA")}
                  min={new Date().toLocaleDateString("en-CA")}
                  sideNoteNamespace={``} // needed to prevent double rendering of side note
                />
              </Condition>
            </Grid>
            <Grid item md={3} xs={2}>
              <NullBooleanInput
                name={`${propertyName}.host_lease_end_excluded`}
                label={`Exclude Lease End Automation`}
                defaultValue={false}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={3} xs={2}>
              <label
                className="input-label whitespace-normal"
                style={{ color: "red" }}
              >{`Warning`}</label>
              <Typography component="div">
                {`This listing is self listed by`}
                <Box fontWeight="fontWeightMedium" display="inline">
                  {` ${selfListing?.user?.first_name} ${selfListing?.user?.last_name} `}
                </Box>
                {`therefore only certain fields can be edited.
               To approve for publish, set property status to
               "Available Matchable" and click the Publish button
               for all sites (if not published). To reject, set
               property status to "Review Rejected" And Leave a comment.`}
              </Typography>
            </Grid>
            <Grid item md={3} xs={2}>
              <OnChange name={`${configName}.self_listing.user.email`}>
                {(curr) => {
                  setNewEmail(curr);
                  setEmailChanged(origEmail !== curr && curr);
                  setUserNoExists("");
                  setNewUser(undefined);
                }}
              </OnChange>
              <TextInput
                name={`${configName}.self_listing.user.email`}
                label={`Listing Owner Email`}
                staticHoverText={`This is the email of the user who self listed this property`}
              />
              {newUser && (
                <>
                  <TextDisplay
                    label={`User Exists`}
                    text={`${newUser.first_name} ${newUser.last_name}`}
                    warningMsg={null}
                  />
                  <TextDisplay
                    label={null}
                    text={`Empire ID: ${newUser.id} Pipedrive ID ${newUser.ext_id}`}
                    warningMsg={null}
                  />
                </>
              )}
              {userNoExists && (
                <Typography style={{ color: "red" }}>{userNoExists}</Typography>
              )}
            </Grid>
            <Grid item md={1} xs={1}>
              <Button
                variant="contained"
                style={{ marginTop: "2em" }}
                disabled={!emailHasChanged}
                onClick={validateEmail}
              >
                Check
              </Button>
              {newUser && (
                <Button
                  variant="contained"
                  style={{ marginTop: "2em" }}
                  onClick={() => claimListing()}
                >
                  Claim
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </GridSection>
  );
}
