export const fieldDictionary = {
  description: "Listing Description",
  twentyfourseven: "24/7",
  securable: "Securable",
  title: "Title",
  list_fee_basis: "List Fee Basis",
  list_fee: "List Fee",
  term_min: "Term",
  warehouse_type: "Type",
  ceiling_clearance: "Ceiling Clearance",
  square_footage_min: "Size",
  status: "Status",
  permitted_auto: "Automotive",
  permitted_food: "Food Preparation",
  permitted_cannabis: "Cannabis",
  permitted_customer: "Customer Facing",
  permitted_sport_complex: "Sports Complex",
  permitted_sport_studio: "Sports Studio",
  permitted_highvol: "High Bay Usage",
  permitted_manufacture: "Heavy Manufacturing",
  docks: "Exclusive Loading Docks",
  drivein_doors: "Exclusive Drive-in Doors",
  shared_docks: "Shared Loading Docks",
  shared_drivein_doors: "Shared Drive-in Doors",
};

export const fieldNamesToReadableStrings = (
  fieldNames: string[],
  dictionary: Record<string, string> = fieldDictionary
): string[] => {
  return fieldNames?.map((name) => dictionary[name]);
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const splitNumber = phoneNumber?.split(/\+1| x/);
  let formattedNumber = "";

  if (
    splitNumber?.length > 1 &&
    splitNumber[0] === "" &&
    splitNumber[1].length === 10
  ) {
    const match = splitNumber[1]?.match(/(\d{3})(\d{3})(\d{4})$/);

    formattedNumber = match
      ? `(${match[1]}) ${match[2]}-${match[3]}`
      : splitNumber[1];
  } else {
    formattedNumber = phoneNumber;
  }

  if (splitNumber?.length === 3) {
    formattedNumber += ` x${splitNumber[2]}`;
  }

  return formattedNumber;
};
