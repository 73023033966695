import React, { useContext } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import Nav from "components/Nav/Nav";

import NotFound from "./NotFound";
import ErrorPage from "./Error";

import Search from "views/Search/Search";
import SearchList from "views/Search/SearchList";
import Settings from "./Settings";
import Organization from "./Organization";

import UserList from "components/Users/UserList";
import UserDetails from "components/Users/UserDetails";
import UserCreateForm from "components/Users/UserCreateForm";
import UserUpdateForm from "components/Users/UserUpdateForm";

import PrivateRoute from "components/PrivateRoute";
import SemiPrivateRoute from "components/SemiPrivateRoute";
import initFontAwesome from "services/init-font-awesome";
import { initializeGoogleMapsAPI } from "components/Map/googleMaps";
import { AppContext } from "contexts/AppContext";
import Footer from "components/Footer";

initFontAwesome();
initializeGoogleMapsAPI();

const NoMatch = () => <h3>Page Not Found</h3>;

function LoggedInApp() {
  const { userPermissions } = useContext(AppContext);

  return (
    <>
      <CssBaseline />
      <Nav />
      <div className="flex justify-center p-10">
        <Switch>
          <PrivateRoute
            exact
            path={[
              "/",
              "/hosts",
              "/guests",
              "/hosts/:id",
              "/guests/:id",
              "/properties",
              "/contacts",
              "/data/configs",
              "/data/requests",
              "/f/hosts/:id",
              "/f/guests/:id",
            ]}
            component={Search}
          />

          <Redirect exact from="/search" to="/" />

          <Redirect exact from="/requests/:id" to="/request/:id" />
          <Redirect exact from="/properties/:id" to="/property/:id" />
          <Redirect exact from="/configs/:id" to="/config/:id" />
          <Redirect
            exact
            from="/f/hosts/:hostId/properties/:id"
            to="/property/:id"
          />

          <PrivateRoute exact path="/notfound" component={() => <NotFound />} />
          <PrivateRoute exact path="/error" component={() => <ErrorPage />} />

          <PrivateRoute exact path="/users" component={UserList} />
          <PrivateRoute
            exact
            path="/users/new"
            component={() => (
              <UserCreateForm allPermissions={userPermissions} />
            )}
          />
          <PrivateRoute exact path="/users/:userID" component={UserDetails} />
          <PrivateRoute exact path="/users/:userID" component={UserDetails} />
          <PrivateRoute
            exact
            path="/users/:userID/edit"
            component={() => (
              <UserUpdateForm allPermissions={userPermissions} />
            )}
          />
          <PrivateRoute
            exact
            path="/search/:searchTerm"
            component={SearchList}
          />

          <PrivateRoute
            exact
            path={["/org/:id", "/property/:id", "/request/:id", "/config/:id"]}
            component={Organization}
          />

          <SemiPrivateRoute exact path="/settings" component={Settings} />

          <Route component={NoMatch} />
        </Switch>
      </div>
      <Footer />
    </>
  );
}

export default LoggedInApp;
